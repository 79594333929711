<template>
  <b-row>
    <b-col
      v-for="(empresa,index) in totais"
      :key="index"
      cols="2"
    >
      <!-- text-center -->
      <b-card
        body-class="pb-50 grafico-view"
        style="min-height: 90%;"
        :class="empresa.hasVencido ? 'centificado-vencido' : ''"
        @click.stop="$router.push({name: empresa.link, query: { filtroRapido: empresa.query ? empresa.query : '' }})"
      >
        <b-row
          class="text-right"
        >
          <b-col
            cols="7"
          >
            <b-avatar
              size="50"
              :variant="empresa.hasVencido ? '' : empresa.iconVariant"
              class="mb-50 mr-50"
            >
              <font-awesome-icon
                :icon="empresa.icon"
                size="2x"
              />
            </b-avatar>
          </b-col>
          <b-col
            cols="5"
          >
            <h4 :class="`font-weight-bolder text-left mt-50 ${ empresa.hasVencido ? 'text-white' : ''}`">
              {{ empresa.valor }}
            </h4>
          </b-col>
        </b-row>
        <b-card-text class="font-small-3 text-center mb-0">
          {{ empresa.titulo }}
        </b-card-text>
      </b-card>
    </b-col>
  </b-row>
</template>

<style>
.centificado-vencido {
  background-color: #ea5455;
  color: white;
}
</style>

<script>
import { defineComponent } from '@vue/composition-api'
import VueApexCharts from 'vue-apexcharts'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import useEmpresa from './useEmpresa'

export default defineComponent({
  components: {
    VueApexCharts,
    FontAwesomeIcon,
  },
  setup() {
    const { totaisEmpresa } = useEmpresa()

    return {
      totais: totaisEmpresa,
    }
  },
})
</script>
