import { ref } from '@vue/composition-api'
import { useRouter } from '@core/utils/utils'
import Vue from 'vue'
import api from '../../services/api'

export default function () {
  const { router } = useRouter()

  const valores = ref([
    {
      titulo: 'Total de empresas',
      icon: 'fa-solid fa-building',
      iconVariant: 'light-success',
      valor: 0,
      link: 'empresa-view',
    },
    {
      titulo: 'Empresas sem certificado / procuração',
      icon: 'fa-solid fa-building-circle-exclamation',
      iconVariant: 'light-danger',
      valor: 0,
      link: 'empresa-view',
      query: 'SemCertificado',
    },
    {
      titulo: 'Certificados com vencimento nos próximos 30 dias',
      icon: 'fa-regular fa-hourglass',
      iconVariant: 'light-warning',
      valor: 0,
      negativo: true,
      link: 'certificado-view',
      query: 'Vencidos30Dias',
    },
    {
      titulo: 'Certificados vencidos/ revogados/ outros',
      icon: 'fa-solid fa-hourglass-end',
      iconVariant: 'light-danger',
      valor: 0,
      negativo: true,
      link: 'certificado-view',
      query: 'Vencidos',
    },
    {
      titulo: 'Procuração vencendo em 30 dias',
      icon: 'fa-solid fa-hourglass-end',
      iconVariant: 'light-warning',
      valor: 0,
      negativo: true,
      link: 'empresa-view',
      query: 'ProcuracaoVencida30Dias',
    },
    {
      titulo: 'Procuração vencida',
      icon: 'fa-solid fa-hourglass-end',
      iconVariant: 'light-danger',
      valor: 0,
      negativo: true,
      link: 'empresa-view',
      query: 'ProcuracaoVencida',
    },
  ])

  api.getContadoresEmpresa()
    .then(payload => {
      valores.value = [
        {
          titulo: 'Total de empresas',
          icon: 'fa-solid fa-building',
          iconVariant: 'light-success',
          valor: payload.data.totalEmpresas,
          link: 'empresa-view',
        },
        {
          titulo: 'Sem certificado / procuração',
          icon: 'fa-solid fa-building-circle-exclamation',
          iconVariant: 'light-danger',
          valor: payload.data.totalEmpresasSemCertificado,
          link: 'empresa-view',
          query: 'SemCertificado',
        },
        {
          titulo: 'Certificados vencendo em 30 dias',
          icon: 'fa-regular fa-hourglass',
          iconVariant: 'light-warning',
          valor: payload.data.certificadosAVencer,
          negativo: true,
          link: 'certificado-view',
          query: 'Vencidos30Dias',
        },
        {
          titulo: 'Certificados vencidos/ revogados/ outros',
          icon: 'fa-solid fa-hourglass-end',
          iconVariant: 'light-danger',
          valor: payload.data.certificadosVencidos,
          negativo: true,
          link: 'certificado-view',
          query: 'Vencidos',
          hasVencido: payload.data.certificadosVencidos > 0,
        },
        {
          titulo: 'Procuração vencendo em 30 dias',
          icon: 'fa-solid fa-hourglass-end',
          iconVariant: 'light-warning',
          valor: payload.data.empresasAVencerProcuracao,
          negativo: true,
          link: 'empresa-view',
          query: 'ProcuracaoVencida30Dias',
        },
        {
          titulo: 'Procuração vencida',
          icon: 'fa-solid fa-hourglass-end',
          iconVariant: 'light-danger',
          valor: payload.data.empresasProcuracaoVencida,
          negativo: true,
          link: 'empresa-view',
          query: 'ProcuracaoVencida',
        },
      ]

      if (payload.data.certificadosVencidosComEmpresasVinculadas > 0 && !router.currentRoute.params.primeiroAcesso && router.currentRoute.query.redirect === undefined) {
        Vue.swal({
          title: '<div style="color:indianred;">Atenção</div>',
          html: '<div style="color:indianred;">Prezado(a) contador(a), existe certificado digital vencidos/revogados/outros em sua base. Regularize o quanto antes para que o sistema possa continuar atualizando suas informações corretamente. As rotinas do <br>e-CAC não vão funcionar até que a atualização seja finalizada.</div>',
          icon: 'warning',
          iconColor: '#ea5455',
          confirmButtonText: 'Atualizar',
          customClass: {
            confirmButton: 'btn btn-danger mr-50',
          },
          buttonsStyling: false,
        })
          .then(result => {
            if (result.value) {
              router.push({ name: 'certificado-view', param: { query: true }, query: { filtroRapido: 'Vencidos' } })
            }
          })
      }
    })

  return {
    totaisEmpresa: valores,
  }
}
